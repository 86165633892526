import {Box, Text} from 'degen';
import React, {ReactElement} from 'react';

const Footer = (): ReactElement => {
  return (
    <Box paddingBottom="10">
      <div className="centerHeader padding-mobile"> 
        <p className="heading-high bottom-0">© 2023 fondationtrust.org</p>
        <p className="subsubheading just opacity-05 background">FTO was deployed to write about local culture in different cities. Contact: info@fondationtrust.org</p>
      </div>
    </Box>
  );
};

export default Footer;

// import {Box, Text} from 'degen';
// import React, {ReactElement} from 'react';

// const Footer = (): ReactElement => {
//   return (
//     <Box textAlign="center" paddingTop="5" paddingBottom="9">
//       <Text>Copyright ©</Text>
//     </Box>
//   );
// };

// export default Footer;
