import React, {ReactElement} from 'react';

import routes from '@/routes';
import {DataT} from '@/types';
import {Stack, Box} from 'degen';
import PostListItem from '../PostListItem/PostListItem';
import {useGetTransactionIndex} from '@/hooks/useArweave';
import {parseErrors} from '@/utils/errors';
import {EmptyBlock, ErrorBlock, Loader} from '..';

const DisplayPosts = ({data}: {data: DataT[]}) => {
  return (
    <Stack>
      {data.map(({transactionId, buffer}) => {
        return (
          <PostListItem
            key={transactionId}
            href={routes.entries.view(transactionId)}
            title={buffer?.title}
          />
        );
      })}
    </Stack>
  );
};

type PostListProps = {
  address?: string;
};

const PostsList = (props: PostListProps): ReactElement => {
  const {address = ''} = props;

  const {data, loading, error, refetch} = useGetTransactionIndex(address);

  if (error) {
    return (
      <ErrorBlock
        title="Fetching failed"
        message={parseErrors(error)}
        retry={refetch}
      />
    );
  }

  if (loading) {
    return <p className="heading opacity-05">Loading...</p>;
  }

  if (!data.length) {
    return ( 
      <Box>
        <div className="centerHeader">
          <div className="box">
            <p className="headingLarge bottom-0">Nothing here</p> 
            <p className="subheading bottom-0">• No entries found at the moment...</p>  
          </div>
        </div> 
      </Box>
    );
  }

  return <DisplayPosts data={data} />;
};

export default PostsList;
