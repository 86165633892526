import React from 'react';

import {ReactNodeNoStrings} from 'degen/dist/types/types';
import {Stack} from 'degen'; 

import Head from "next/head";
import Header from './Header';
import Footer from './Footer';

type PublicLayoutProps = {
  children: ReactNodeNoStrings;
};

const PublicLayout = (props: PublicLayoutProps): JSX.Element => {
  const {children} = props;

  return (
    <Stack>
      <Head> 
        <meta charSet="UTF-8" />
        <meta name="keywords" content=""/>
        <meta name="author" content="fondation trust"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>FTO by fondation trust</title> 
        <meta name="description" content="FTO was deployed to write about local culture in different cities. To become a writer, please contact: info@fondationtrust.org" />  
      </Head>
      <Header />
      <Stack>{children}</Stack>
      <Footer />
    </Stack>
  );
}; 

export default PublicLayout;
