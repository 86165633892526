import {Button, Card, IconExclamation, Stack, Text, Box} from 'degen';
import React from 'react';

type ErrorBlockProps = {
  icon?: JSX.Element;
  title: string;
  message: string;
  retry?: () => void;
};

const ErrorBlock = (props: ErrorBlockProps): JSX.Element => {
  const {
    icon = <IconExclamation size="32" color="red" />,
    title,
    message,
    retry,
  } = props;

  return (
    <Card> 
      <Box>
        <div className="centerHeader">
          <div className="box">
            <p className="headingLarge bottom-0">{title}</p> 
            <p className="subheading">• {message}</p> 
            {retry && <Button onClick={retry}>Retry</Button>}
          </div>
        </div> 
      </Box>
    </Card>
  );
};

export default ErrorBlock;

