import Link from 'next/link'; 
import React, {ReactElement} from 'react';
import {Box, Field, Button, IconUserSolid, Stack} from 'degen';

import {useWeb3} from '@/hooks/useWeb3';
import {Logo, PostsList} from '@/components';
import {addEllipsis} from '@/utils/string';

const Header = (): ReactElement => {
  const {address, connect, disconnect} = useWeb3();

  const connectToggle = async () => {
    try {
      if (address) {
        disconnect();
        // alert(
        //   'To fully disconnect, click "Connected" on MetaMask and disconnect your account.',
        // );
      } else {
        await connect();
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error';
      alert(`Connection attempt failed: ${errorMessage}`);
    }
  };

  return ( 
    <> 
    <Box>
      {/*<div className="desktop">
        <p className="heading copyright"><img src="../public/fto.png" width="100"/></p>
      </div>*/}
      <div className="centerHeader padding-mobile">
       <Stack> 
        <Stack direction="horizontal" space="2">
          <Button 
            onClick={connectToggle}
          >
            <p className="heading bottom-0"><span className="background">{address ? 'Disconnect' : 'Connect Wallet'}</span></p> 
          </Button>
          {address && (
            <Link href={`/profile/${address}`} passHref>
              <Button>
                <p className="heading bottom-0">{addEllipsis(address as string)}</p>
              </Button>
            </Link>
          )}  
        </Stack>
        {/*<div className="mobile">
          <p className="subheading top-minus-5">e. 2018</p>
        </div>*/}
       </Stack>
      </div>
    </Box>
    </>
  );
};

export default Header;

// import Link from 'next/link';
// import React, {ReactElement} from 'react';
// import {Box, Field, Button, IconUserSolid, Stack} from 'degen';

// import {useWeb3} from '@/hooks/useWeb3';
// import {Logo, PostsList} from '@/components';

// const Header = (): ReactElement => {
//   const {address, connect, disconnect} = useWeb3();

//   const connectToggle = async () => {
//     try {
//       if (address) {
//         disconnect();
//         alert(
//           'To fully disconnect, click "Connected" on MetaMask and disconnect your account.',
//         );
//       } else {
//         await connect();
//       }
//     } catch (error) {
//       const errorMessage =
//         error instanceof Error ? error.message : 'Unknown Error';
//       alert(`Connection attempt failed: ${errorMessage}`);
//     }
//   };

//   return ( 
//     <> 
//     <Box
      // display="flex"
      // justifyContent="space-between"
      // flexDirection="row"
      // borderBottomWidth="0.375"
      // padding="4"
//     >
//       <Logo /> 
//       <Stack direction="horizontal">
//         {address && (
//           <Link href={`/profile/${address}`} passHref>
//             <Button shape="circle" variant="secondary">
//               <IconUserSolid />
//             </Button>
//           </Link>
//         )}
//         <Button
//           variant="highlight"
//           width={{xs: 'full', md: 'max'}}
//           onClick={connectToggle}
//         >
//           {address ? 'Disconnect' : 'Connect Wallet'}
//         </Button>
//       </Stack>
//     </Box>
//     </>
//   );
// };

// export default Header;


